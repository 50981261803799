


































































import { Component, Mixins } from 'vue-property-decorator'
import { required, email } from '@/services/Validation'
import { Auth } from '@/api'
import { FormHelper } from '@/mixins'

@Component({
  components: {
    Logo: () => import('@/components/Logo.vue'),
    Recaptcha: () => import('@/components/Recaptcha.vue')
  }
})
export default class ForgotPassword extends Mixins(FormHelper) {
  userEmail = ''
  recaptcha = ''
  required = required
  email = email
  error = false
  success = false
  message = ''

  setRecaptcha(token: string) {
    this.recaptcha = token
  }

  async validate() {
    const { userEmail, recaptcha } = this
    const errors = await Auth.askNewPassword({
      email: userEmail,
      recaptcha
    })

    if (errors.detail) {
      this.error = true
      this.message = errors.detail
      this.$root.$emit('resetCaptcha')
    } else {
      this.success = true
      this.message = errors
    }
  }
}
